import { createContext, useContext } from 'react';
import { timeContextProps } from 'utils/type';

export const defaultContext: timeContextProps = {
  // @ts-ignore
  timeRange: { from: new Date(), to: new Date() },
  guiValues: {
    current: {
      aggTime: '1s',
      aggFn: { fn: 'last' },
      Agg: false,
      Last: false,
      lastCount: 50,
  
      startDateTime: '2024-06-18 12:10:00',
      endDateTime: '2024-06-18 12:15:00',
      pbaggTime: '1s',
      pbaggFn: { fn: 'last'},
      pbisInPlayBack: false,
      pbstopPlay: () => {},
      pbplayBack: () => {},
      
    }
  },
};

export const TimeRangeContext = createContext<timeContextProps>(defaultContext);

export const useTimeRangeContext = () => useContext(TimeRangeContext);
