import React, { useState } from 'react'
import PlotlyChart from './PlotlyChart';
import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styles
import * as vbScheme from './VBScheme'; // Import your styles
import { handleCopyMneName, determineColorNew } from 'utils/function';
import { TbProps, VisibleTooltips } from 'utils/type';

const ValueBpx: React.FC<TbProps> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div className={cx(vbScheme[data.className as vbScheme.ClassKey])}>
      {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )} 
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
    </div>
  )
}

export default ValueBpx;
