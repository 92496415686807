import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styles
import { handleCopyMneName, determineColorNew } from 'utils/function';
import { TLM, InnerData } from 'utils/type';

type VisibleTooltips = { [key: string]: boolean };

interface LvpsProp {
  data: {
    temp: TLM;
    unit: string;
    value: InnerData;
  };
}

const LVPS: React.FC<LvpsProp> = ({ data }) => {
  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips((prevVisibleTooltips) => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key],
    }));

    setTimeout(() => {
      setVisibleTooltips((prevVisibleTooltips) => ({
        ...prevVisibleTooltips,
        [key]: false,
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops((prevVisibleLimitPops) => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key],
    }));
  };

  return (
    <div className={css`
    display:flex;
    flex-direction: column;
    align-items: center;
    `}>
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Top} id="target-top-1" style={{ left: 175, opacity: 0 }} />
      <Handle type="target" position={Position.Top} id="target-top-2" style={{ left: 142, opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      {/* <Handle type="target" position={Position.Right} id="target-right" />
      <Handle type="target" position={Position.Bottom} id="target-bottom" /> */}
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />

      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      {/* <Handle type="source" position={Position.Right} id="source-right" />
      <Handle type="source" position={Position.Bottom} id="source-bottom" /> */}
        <div>
          <svg
            fill="rgb(56,56,56)"
            width="300px"
            height="230px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-.5 -0.5 300 230"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <rect x="60" y="30" width="180" height="180" fill="rgb(100,100,100)" stroke="black" stroke-width="6" rx="10" />
            <path d="M30 70 Q45 40 60 70 T90 70" stroke="black" fill="none" stroke-width="4" />
            <text x="10" y="50" font-size="36"  stroke="black"  fill='black'>
              AC
            </text>
            <line x1="240" y1="120" x2="285" y2="120" stroke="black" stroke-width="6" />
            <circle cx="285" cy="120" r="8" fill="black" />
            <text x="250" y="90" font-size="36"  stroke="black" fill='black'>
              DC
            </text>
            <text x="110" y="130" font-size="36" stroke="black" fill='black'>
              LVPS
            </text>
          </svg>
        </div>

        
        <div className={scheme.textboxItems}>
          {data.value &&
            Object.entries(data.value).map(([key, innerData]) => (
              <div className={cx(scheme.svgValueSpanContainer, css`width: 280px`)} key={key}>
                <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick(key)}>
                  {key}
                  {visibleTooltips[key] && (
                    <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>
                      {innerData.dbData.mne}
                    </span>
                  )}
                  <span data-comp="fname" className={scheme.svgFnameTooltip}>
                    {innerData.fname}
                  </span>
                </span>
                <span
                  onClick={() => handleValueChartClick(key)}
                  className={cx(
                    scheme.svgValue,
                    css`
                      color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ''};
                    `,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && (
                  <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>
                )}
                {visibleLimitPops[key] && (
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                      Close Plot
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
      </div>
  );
};

export default LVPS;
