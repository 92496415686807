import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import PlotlyChart from "./PlotlyChart";
import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styles
import { handleCopyMneName, determineColorNew } from "utils/function";
import { TLM, InnerData, VisibleTooltips } from "utils/type";

interface DledProp {
  data: {
    temp: TLM;
    unit: string;
    value: InnerData;
    left: boolean;
    right: boolean;
  };
}

const DLED: React.FC<DledProp> = ({ data }) => {

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key]
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  return (
    <div className="">
      {/* <Handle type="target" position={Position.Top} id="target-top" style={{background: "rgb(38,38,38)" }}/> */}
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}} />
      
      {/* <Handle type="target" position={Position.Bottom} id="target-bottom" style={{background: "rgb(38,38,38)" }}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{background: "rgb(38,38,38)" }}/> */}
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>

      <Handle type="source" position={Position.Left} id="source-left-1" style={{top:40.5, opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{top:40, opacity:0}}/>

      <Handle type="source" position={Position.Left} id="source-left-2" style={{top:22, opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-2" style={{top:22, opacity:0}}/>
      {/*<Handle type="source" position={Position.Bottom} id="source-bottom" style={{background: "rgb(38,38,38)" }}/> */}
      <div className="">
      {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
      </div>
    </div>
  );
};

export default DLED;
