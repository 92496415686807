import React from 'react'
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';

const ICP: React.FC = () => {

  const icpContainer = css`
  width: 675px;
  height: 1800px;
  z-index: 0;
  background-color: rgb(22, 65, 108); /*  rgb(22, 65, 108) rgb(25, 113, 201)*/
  border: 5px solid black;
  color: rgb(255, 255, 255);
  `

  return (
    <div className={icpContainer}>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}  />
      <Handle type="target" position={Position.Top} id="target-top-1" style={{ left: 100,opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-3" style={{ left: 80,opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-2" style={{ left: 200,opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-4" style={{ left: 220,opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left"  style={{opacity:0}} />
      <Handle type="target" position={Position.Right} id="target-right"  style={{opacity:0}} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}  /> 
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}  />
      <Handle type="source" position={Position.Top} id="source-top-1" style={{ left: 300,opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top-2" style={{ left: 200,opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top-3" style={{ left: 100,opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top-4" style={{ left: 320,opacity:0}}/>

      <Handle type="source" position={Position.Left} id="source-left"  style={{opacity:0}} />
      <Handle type="source" position={Position.Right} id="source-right"  style={{opacity:0}} />
      <Handle type="source" position={Position.Bottom} id="source-bottom"  style={{opacity:0}} />

      <Handle type="target" position={Position.Left} id="target-left-top-1" style={{ top: 20,opacity:0}} />
      <Handle type="target" position={Position.Left} id="target-left-top-2" style={{ top: 80,opacity:0}} />

      <Handle type="source" position={Position.Left} id="source-left-top-1" style={{ top: 197,opacity:0}} />
      <Handle type="source" position={Position.Left} id="source-left-1" style={{ top: 237,opacity:0}} />
      <Handle type="source" position={Position.Left} id="source-left-top-2" style={{ top: 550,opacity:0}} />
      <Handle type="source" position={Position.Left} id="source-left-2" style={{ top: 570,opacity:0}} />
      <Handle type="source" position={Position.Left} id="source-left-top-3" style={{ top: 1490,opacity:0}} />
      <Handle type="source" position={Position.Left} id="source-left-3" style={{ top: 1510,opacity:0}} />

      <p>Instrument Control Package(ICP)</p>

    </div>
  )
}

export default ICP;
