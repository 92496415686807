import { DataSourceApi } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

interface Props {
  data: {
    mne: string
    sc: string
  }
}

const PlotlyChart: React.FC<Props> = ({data}) => {

  const [plotData, setPlotData] = useState<{ times: Date[], values: number[] }>({ times: [], values: [] });
  const [yAxisRange, setYAxisRange] = useState<[number, number]>([0, 0]);

  const fetchData = async (query: string, dataSourceName: string) => {
    // Example query incorporating a variable
    const dataSource: DataSourceApi = await getDataSourceSrv().get(dataSourceName);

        // Execute the query and wait for the promise to resolve
        // @ts-ignore
      const result = await dataSource.query({ targets: [{ query: query, refId: 'comQ' }] }).toPromise();
      
      return result;

  };

  useEffect(() => {
    const exQuery = `from(bucket: "telemetry")
      |> range(start: -15d)
      |> filter(fn: (r) =>
          r._measurement == "telemetry" and 
          r.facility == "${data.sc}" and
          r["_field"] == "cnv_value" and
          r.mne == "${data.mne}"
      )
      |> sort(columns: ["_time"],desc: true)
      |> limit(n: 50)
    `;

    fetchData(exQuery, 'INFLUX_TEST').then(result => {
      if (result.data && result.data[0] && result.data[0].fields) {
        const queryData = result.data[0].fields;
        const times = queryData[0] ? queryData[0].values.map((date: string | number) => new Date(date)) : [];
        const values = queryData[1] ? queryData[1].values : [];
        setPlotData({ times, values });

        const minY = Math.min(...values);
        const maxY = Math.max(...values);
        const yPadding = (maxY - minY) * 0.1; // 10% padding
        setYAxisRange([minY - yPadding, maxY + yPadding]);
      }
    });
  }, [data.mne, data.sc]); // Effect will re-run if data.mne changes

  return (
    <div>
      <Plot
        divId='plotlyC'
        className='plotly-chart1'
        data={[
          {
            x: plotData.times,
            y: plotData.values,
            type: 'scatter',
            mode: 'lines+markers',
            line: { color: 'white' },  // Setting line color to whit
            marker: {color: 'rgb(68, 169, 241)', size:20},
          }
        ]}
        layout={{
          width: 1020,
          height: 740,
          paper_bgcolor: 'black',   // Background color of the plotting area
          plot_bgcolor: 'black',    // Background color of the data area
          font: {
            color: 'white'          // Color of all ext in the chart
          },
          yaxis: {
            range: yAxisRange // Adjusted range with padding
          },
          xaxis: {
            type: 'date', // Ensuring x-axis is treated as date
          },
          margin: {
            t: 5 // Adjust the top margin to reduce the empty space at the top
          }
          
        }}
        config={{
          displayModeBar: false // This disables the mode bar
        }}
      />
    </div>

    
    
  );
};

export default PlotlyChart;
