import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import { determineColorNew, handleCopyMneName } from 'utils/function';
import { TLM, InnerData } from 'utils/type';

interface MotorPosProp {
  telemetry: string | number;
  limit: string;
  unit: string;
  live: string;
  mne: string;
}

type VisibleTooltips = { [key: string]: boolean };

interface FilterProp {
  data: {
    disk1: string;
    disk2: string;
    disk3: string;
    disk4: string;
    disk5: string;
    disk6: string;
    power: string;
    temp: TLM;
    unit: string;
    value: InnerData;
    motorPos: MotorPosProp;
    motor: TLM;
  }
}

const FilterWheel: React.FC<FilterProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const [showPowerInfo, setShowPowerInfo] = useState(false);

  const handlePowerEllipseClick = () => {
    setShowPowerInfo(!showPowerInfo);

    setTimeout(() => { setShowPowerInfo(false) }, 2000);
  };

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key]
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 2000);
  };


  const getColor = (motorPos: string, index: string) => {
    if (!data.motorPos) {
      return;
    }
    if (data.motorPos.telemetry === motorPos || data.motorPos.telemetry === index) {
      return "rgb(156, 156, 156)"; // Green for the selected and ON disk
    } else {
      return "rgb(38,38,38)"; // Default gray color
    }
  }

  const getColorMotor = () => {
    if (!data.motor) {
      return;
    }
    if (data.motor.telemetry === "BUSY") {
      return "green"; // Green for the selected and ON disk
    } else {
      return "gray"; // Default gray color
    }
  }

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div className="">
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}} />
      <Handle type="target" position={Position.Left} id="target-left"  style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right"  style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom"  style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top"  style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}} />
      <Handle type="source" position={Position.Left} id="source-left-1" style={{ top: 200, left: -30, opacity: 0 }} />

      <Handle type="source" position={Position.Right} id="source-right"  style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{ top: 200, right: -30, opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}} />
      <div className="">

        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="150px" height="155px" viewBox="-0.5 -0.5 380 380">
          <defs />
          <g>
            <ellipse cx="190" cy="190" rx="175" ry="175" fill="rgb(38,38,38)" stroke="purple" strokeWidth="15" strokeMiterlimit="10" pointerEvents="all" />
            <ellipse cx="125" cy="90" rx="40" ry="40" fill={getColor("SRF2", "1")} stroke="purple" strokeWidth="15" strokeMiterlimit="10" pointerEvents="all" />
            <ellipse cx="255" cy="90" rx="40" ry="40" fill={getColor("LYA_N_HOME", "2")} stroke="purple" strokeWidth="15" strokeMiterlimit="10" pointerEvents="all" />
            <ellipse cx="71" cy="185" rx="40" ry="40" fill={getColor("CAF2", "3")} stroke="purple" strokeWidth="15" strokeMiterlimit="10" pointerEvents="all" />
            <ellipse cx="309" cy="185" rx="40" ry="40" fill={getColor("LYA_XN", "4")} stroke="purple" strokeWidth="15" strokeMiterlimit="10" pointerEvents="all" />
            <ellipse cx="125" cy="290" rx="40" ry="40" fill={getColor("OPEN", "5")} stroke="purple" strokeWidth="15" strokeMiterlimit="10" pointerEvents="all" />
            <ellipse cx="255" cy="290" rx="40" ry="40" fill={getColor("UNKNOWN", "6")} stroke="purple" strokeWidth="15" strokeMiterlimit="10" pointerEvents="all" />

            <ellipse cx="190" cy="190" rx="55" ry="55" fill="rgb(38,38,38)" stroke={getColorMotor()} strokeWidth="15" pointerEvents="all" onClick={handlePowerEllipseClick} />
            <path d="M 220 190 L 220 205 L 160 205" fill="none" stroke={getColorMotor()} strokeWidth="10" strokeLinecap="square" strokeMiterlimit="10" pointerEvents="all" />
            <path d="M 160 205 L 160 225" fill="none" stroke={getColorMotor()} strokeWidth="10" strokeMiterlimit="10" pointerEvents="stroke" />

            <path d="M 169.79 155 L 169.79 185" fill="none" stroke={getColorMotor()} strokeWidth="10" strokeMiterlimit="10" pointerEvents="stroke" />
            <path d="M 209.79 155 L 210.21 185" fill="none" stroke={getColorMotor()} strokeWidth="10" strokeMiterlimit="10" pointerEvents="stroke" />
            <path d="M 209.37 155 L 189.79 175" fill="none" stroke={getColorMotor()} strokeWidth="10" strokeMiterlimit="10" pointerEvents="stroke" />
            <path d="M 169.79 155 L 189.79 175" fill="none" stroke={getColorMotor()} strokeWidth="10" strokeMiterlimit="10" pointerEvents="stroke" />
          </g>
        </svg>
        {showPowerInfo && <div>
          <div className={scheme.svgValueSpanContainer}>
            <span className={scheme.svgKey}>M<span className={scheme.svgFnameTooltip}>Motor Busy</span></span>
            <span
              className={cx(
                scheme.svgValue,
                css`color: ${data.motor && data.motor.live ? data.motor.live : ""}`,
                scheme.classMap[determineColorNew(data.motor?.limit)]
              )}
            >
              {data.motor.telemetry}
            </span>
          </div>
        </div>}
        {data.motorPos && data.motorPos.telemetry === 'SRF2' || data.motorPos && data.motorPos.telemetry === "1" && <span >SRF2</span>}
        {data.motorPos && data.motorPos.telemetry === 'LYA_N_HOME' || data.motorPos && data.motorPos.telemetry === "2" && <span >LYA_N_HOME</span>}
        {data.motorPos && data.motorPos.telemetry === 'CAF2' || data.motorPos && data.motorPos.telemetry === "3" && <span >CAF2</span>}
        {data.motorPos && data.motorPos.telemetry === 'LYA_XN' || data.motorPos && data.motorPos.telemetry === "4" && <span >LYA_XN</span>}
        {data.motorPos && data.motorPos.telemetry === 'OPEN' || data.motorPos && data.motorPos.telemetry === "5" && <span>OPEN</span>}
        {data.motorPos && data.motorPos.telemetry === 'UNKNOWN' || data.motorPos && data.motorPos.telemetry === "6" && <span>UNKNOWN</span>}
        <div>
          <span className="name-container">
            Filter Wheel
          </span>
        </div>

        <div className={scheme.textboxItems}>

          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] &&
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FilterWheel;
