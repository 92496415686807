// createNodes.ts

import { TelemetryDictionary } from 'utils/type';
import { Node } from 'reactflow';

export const createNodes = (
  telemetryDictionary: TelemetryDictionary,
  pbTime: string,
  pbLoop: number,
) => {
  const nodes: Node[] = [
    { id: 'GCI Optical Module', type: 'bench', position: { x: 0, y: 100 }, data: {} },
    { id: 'GCI Imager Module', type: 'gci_imager', parentNode: 'GCI Optical Module', position: { x: 0, y: 125 }, data: {} },
    { id: 'NFI Module', type: 'nfi', parentNode: 'GCI Imager Module', position: { x: 25, y: 75 }, data: {} },
    { id: 'WFI Module', type: 'wfi', parentNode: 'GCI Imager Module', position: { x: 1025, y: 75 }, data: {} },
    { id: 'ICP Module', type: 'icp', parentNode: 'GCI Optical Module', position: { x: 2100, y: 300 }, data: {} },

    { id: 'ICP Low Voltage Power Supply', type: 'lvps', parentNode: 'ICP Module', position: { x: 188, y: 1300 }, data: { temp: telemetryDictionary['LVPS Temp'], name: 'Low Voltage Power Supply', className: 'icpContainer', value: {'ICPi': { 'dbData': telemetryDictionary['ICP Pwr IMon'] }, 'GCIi': { 'dbData': telemetryDictionary['GCI General Pwr IMon'], 'fname': 'GCI Power IMon' }, 'T': { 'dbData': telemetryDictionary['LVPS Temp'], 'fname': 'Low Voltage Power Supply' } } } },
    { id: 'ICP Data Control Board', type: 'textbox', parentNode: 'ICP Module', position: { x: 55, y: 950 }, data: { name: 'Data Control Board', className: 'icpContainer', value: { 'Instrument TLM': { 'dbData': telemetryDictionary['Instrument TLM'], 'fname': 'Instrument telemetry' }, 'Cmd Interface': { 'dbData': telemetryDictionary['Command Interface'] } } } },
    { id: 'ICP Power Switching & MUX board', type: 'textbox', parentNode: 'ICP Module', position: { x: 55, y: 100 }, data: { power: telemetryDictionary['Bus SW ICP A'], power1: telemetryDictionary['Bus SW ICP B'], name: 'Power & MUX board', className: 'icpContainer', value: { 'ICP Core Temp': { 'dbData': telemetryDictionary['ICP Core Temp'], 'fname': 'ICP Core Temperature' }, 'PSM Temp': { 'dbData': telemetryDictionary['ICP PSM Temp'], 'telemetry': 25.35, 'unit': 'C' }, 'DCB Temp': { 'dbData': telemetryDictionary['ICP DCB Temp'], 'telemetry': 25.33, 'unit': 'C' }, 'FPGA Temp': { 'dbData': telemetryDictionary['ICP FPGA Temp'], 'telemetry': 26.74, 'unit': 'C' }, 'P5VA Vmon': { 'dbData': telemetryDictionary['ICP P5VA Vmon'], 'telemetry': 5.047, 'unit': 'V' }, 'P5VD Vmon': { 'dbData': telemetryDictionary['ICP P5VD Vmon'], 'telemetry': 4.995, 'unit': 'V' }, 'P3V3D Vmon': { 'dbData': telemetryDictionary['ICP P3V3D Vmon'] }, 'Moabp3p3v': { 'dbData': telemetryDictionary['ICP moabp3p3v Vmon'] }, 'Vref 4p5Vv': { 'dbData': telemetryDictionary['ICP Vref 4P5V Vmon'] }, 'Vref2 4p5Vv': { 'dbData': telemetryDictionary['ICP Vref 2_4p5v Vmon'] }, 'Pwr Therm lock': { 'dbData': telemetryDictionary['Pwr Therm lock'], 'fname': 'Power Therm Interlock' } } } },

    { id: 'wfi-door', type: 'door', parentNode: 'WFI Module', position: { x: 620, y: 2000 }, data: { position: telemetryDictionary["WFI Door Position"], name: 'WFI', 'power': telemetryDictionary['Bus SW Door'], 'select': telemetryDictionary['Door Act Select'], value: { 'A': { 'dbData': telemetryDictionary['Door Act Armed'] }, "F": { 'dbData': telemetryDictionary['Door Act Firing'] } } } },
    { id: 'nfi-door', type: 'door', parentNode: 'NFI Module', position: { x: 10, y: 2000 }, data: { position: telemetryDictionary["NFI Door Position"], 'power': telemetryDictionary['Bus SW Door'], 'select': telemetryDictionary['Door Act Select'], name: 'NFI', value: { 'A': { 'dbData': telemetryDictionary['Door Act Armed'] }, "F": { 'dbData': telemetryDictionary['Door Act Firing'] } } } },

    { id: 'wfi-fw', type: 'fw', parentNode: 'WFI Module', position: { x: 675, y: 1615 }, data: { motorPos: telemetryDictionary['WFI Filter Position'], "power": "OFF", 'motor': telemetryDictionary["WFI Motor Busy"], 'temp': telemetryDictionary['WFI Fw Temp'], value: { "T": { 'dbData': telemetryDictionary['WFI Fw Temp'], 'fname': 'WFI Filter Wheel Temperature' }, 'Pos': { 'dbData': telemetryDictionary['WFI Filter Position'], fname: 'WFI Filter Position' } } } },
    { id: 'nfi-fw', type: 'fw', parentNode: 'NFI Module', position: { x: 10, y: 1615 }, data: { motorPos: telemetryDictionary['NFI Filter Position'], motor: telemetryDictionary["NFI Motor Busy"], "power": "OFF", 'temp': telemetryDictionary['NFI Fw Temp'], value: { "T": { 'dbData': telemetryDictionary['NFI Fw Temp'], 'fname': 'NFI Filter Wheel Temperature' }, 'Pos': { 'dbData': telemetryDictionary['NFI Filter Position'], fname: 'NFI Filter Position' } } } },

    { id: 'WFI Optics & Baffles', type: 'optics', parentNode: 'WFI Module', position: { x: 750, y: 1125 }, data: { name: 'WFI Optics & Baffles', className: '', temp1: telemetryDictionary['WFI Opt 1 Temp'], temp2: telemetryDictionary['WFI Opt 2 Temp'], value: { 'T1': { 'dbData': telemetryDictionary['WFI Opt 1 Temp'], 'telemetry': '20.8', 'unit': 'C', 'fname': 'WFI Optics 1 Temperature' }, 'T2': { 'dbData': telemetryDictionary['WFI Opt 2 Temp'], 'telemetry': '23.5', 'unit': 'C', 'fname': 'WFI Optics 2 Temperature' } } } },

    { id: 'WFI DLED', type: 'dled', parentNode: 'WFI Module', position: { x: 375, y: 1155 }, data: { right: true, name: 'WFI DLED', temp: telemetryDictionary['WFI DLED Temp'], value: { 'T': { 'dbData': telemetryDictionary['WFI DLED Temp'], 'telemetry': 24.36, 'unit': 'C', 'fname': 'WFI DLED Temperature' } } } },

    { id: 'Preamp & Motor Drive Box', type: 'textbox', parentNode: 'GCI Imager Module', position: { x: 767.5, y: 1525 }, data: { name: 'Preamp & Motor Box', prwImon: telemetryDictionary['Fw Pwr IMon'], nfiMotorPos: telemetryDictionary['NFI Filter Position'], wfiMotorPos: telemetryDictionary['WFI Filter Position'], nfiMotorBusy: telemetryDictionary["NFI Motor Busy"], wfiMotorBusy: telemetryDictionary["WFI Motor Busy"], className: 'dled', value: { 'Temp': { 'dbData': telemetryDictionary['PMD Temp'], 'fname': 'PMD Temperature' }, 'Op Ht Temp': { 'dbData': telemetryDictionary['PMD Opht Temp'], 'fname': 'PMD Operational Heater Temperature' }, 'Fw Pwr IMon': { 'dbData': telemetryDictionary['Fw Pwr IMon'], 'fname': 'Filter wheel Power Current' } } } },
    { id: 'DLED', type: 'textbox', parentNode: 'GCI Imager Module', position: { x: 767.5, y: 1160.5 }, data: { name: 'DLED',powerName: 'Power', className: 'dled', value: { 'HV Dled': { 'dbData': telemetryDictionary['HV DLED'] }, 'Pwr Mux': { 'dbData': telemetryDictionary['DLED Pwr Mux'], 'fname': 'DLED Power Mux' } } } },
    { id: 'NFI Optics & Baffles', type: 'optics', parentNode: 'NFI Module', position: { x: 70, y: 1125 }, data: { name: 'NFI Optics & Baffles', className: '', temp1: telemetryDictionary['NFI Opt 1 Temp'], temp2: telemetryDictionary['NFI Opt 2 Temp'], value: { 'T1': { 'dbData': telemetryDictionary['NFI Opt 1 Temp'], 'telemetry': '20.8', 'unit': 'C', 'fname': 'NFI Optics 1 Temperature' }, 'T2': { 'dbData': telemetryDictionary['NFI Opt 2 Temp'], 'telemetry': '23.5', 'unit': 'C', 'fname': 'NFI Optics 2 Temperature' } } } },
    { id: 'NFI DLED', type: 'dled', parentNode: 'NFI Module', position: { x: 350, y: 1155 }, data: { left: true, name: 'NFI DLED', temp: telemetryDictionary['NFI DLED Temp'], value: { 'T': { 'dbData': telemetryDictionary['NFI DLED Temp'], 'telemetry': 24.36, 'unit': 'C', 'fname': 'NFI DLED Temperature' } } } },

    { id: 'wfi-cam', type: 'cam', parentNode: 'WFI Module', position: { x: 670, y: 550 }, data: { name: 'WFI Camera', className: "camContainer", "power": telemetryDictionary['WFI Cam Pwr'], 'state': telemetryDictionary["WFI Image State"], value: { 'I': { 'dbData': telemetryDictionary['WFI Cam IMon'], 'fname': 'WFI Cam IMon' }, 'C': { 'dbData': telemetryDictionary['WFI Comp'], 'fname': 'WFI Comp' }, 'SSL': { 'dbData': telemetryDictionary['WFI Image Sec Since Last'], 'fname': 'WFI Seconds Since Last Image' }, 'In': { 'dbData': telemetryDictionary['WFI Image Inner'], 'fname': 'WFI Image Inner Count' }, 'Out': { 'dbData': telemetryDictionary['WFI Image Outer'], 'fname': 'WFI Image Outer Count' }, 'InR': { 'dbData': telemetryDictionary['WFI Image Inner Req'], 'fname': 'WFI Image Inner Count Requested' }, 'OutR': { 'dbData': telemetryDictionary['WFI Image Outer Req'], 'fname': 'WFI Image Outer Count Requested' }, 'Mac': { 'dbData': telemetryDictionary['WFI Image Marco'], 'fname': 'WFI Image Marco' } } } },

    { id: 'WFI Htr', type: 'heater', parentNode: 'WFI Module', position: { x: 330, y: 650 }, data: { name: 'WFI Cam Heater', power: telemetryDictionary['WFI Cam Survival Heater'], value: { 'E': { 'dbData': telemetryDictionary['WFI CAM HTR Enabled'], 'fname': 'WFI Cam Heater Enabled' }, 'SSP': { 'dbData': telemetryDictionary['WFI Cam HTR Survival Set Point'], 'fname': 'WFI Camera Heater Survival Set Point' }, 'OSP': { 'dbData': telemetryDictionary['WFI Cam HTR Operational Set Point'], 'fname': 'WFI Camera Heater Operational Set Point' }, 'OT': { 'dbData': telemetryDictionary['WFI Cam Opht Temp'], 'fname': 'WFI Cam Operational Heater Temperature' } } } },
    { id: 'Bench Htr', type: 'heater', parentNode: 'GCI Imager Module', position: { x: 1020, y: 760 }, data: { name: 'Bench Heater', 'classname': 'bench-heater', power: telemetryDictionary['Bench Survival Heater'], value: { 'E': { 'dbData': telemetryDictionary['Bench Forward HTR Enabled'], 'fname': 'Bench Forward Heater Enabled' }, 'SSP': { 'dbData': telemetryDictionary['Bench Forward HTR Survival Set Point'], 'fname': 'Bench Forward HTR Survival Set Point' }, 'OSP': { 'dbData': telemetryDictionary['Bench Forward HTR Operational Set Point'], 'fname': 'Bench Forward HTR Operational Set Point' } } } },
    { id: 'NFI Htr', type: 'heater', parentNode: 'NFI Module', position: { x: 325, y: 630 }, data: { name: 'NFI Cam Heater', power: telemetryDictionary['NFI Cam Survival Heater'], value: { 'E': { 'dbData': telemetryDictionary['NFI CAM HTR Enabled'], 'fname': 'NFI Cam Heater Enabled' }, 'SSP': { 'dbData': telemetryDictionary['NFI Cam HTR Survival Set Point'], 'fname': 'NFI Camera Heater Survival Set Point' }, 'OSP': { 'dbData': telemetryDictionary['NFI Cam HTR Operational Set Point'], 'fname': 'NFI Camera Heater Operational Set Point' }, 'OT': { 'dbData': telemetryDictionary['NFI Cam Opht Temp'], 'fname': 'NFI Cam Operational Heater Temperature' } } } },
    { id: 'GCI Imager General', type: 'valuebox', parentNode: 'GCI Imager Module', position: { x: 700, y: 750 }, data: { name: 'GCI General', className: 'gciGeneral', value: { 'P': { 'dbData': telemetryDictionary['GCI General Power'], 'fname': 'GCI Power' }, 'OHI': { 'dbData': telemetryDictionary['GCI General Opht IMon'], 'fname': 'GCI Operational Heater IMon' }, 'DMA': { 'dbData': telemetryDictionary['GCI General DMA State'], 'fname': 'GCI Direct Memory Access State' }, 'TLM': { 'dbData': telemetryDictionary['GCI General TLM State'], 'fname': 'GCI Telemetry State' } } } },

    { id: 'nfi-cam', type: 'cam', parentNode: 'NFI Module', position: { x: 10, y: 550 }, data: { name: 'NFI Camera', className: "camContainer", "power": telemetryDictionary['NFI Cam Pwr'], 'state': telemetryDictionary["NFI Image State"], value: { 'I': { 'dbData': telemetryDictionary['NFI Cam IMon'], 'fname': 'WFI Cam IMon' }, 'C': { 'dbData': telemetryDictionary['NFI Image Comp'], 'fname': 'NFI Comp' }, 'SSL': { 'dbData': telemetryDictionary['NFI Image Sec Since Last'], 'fname': 'NFI Seconds Since Last Image' }, 'In': { 'dbData': telemetryDictionary['NFI Image Inner'], 'fname': 'NFI Image Inner Count' }, 'Out': { 'dbData': telemetryDictionary['NFI Image Outer'], 'fname': 'NFI Image Outer Count' }, 'InR': { 'dbData': telemetryDictionary['NFI Image Inner Req'], 'fname': 'NFI Image Inner Count Requested' }, 'OutR': { 'dbData': telemetryDictionary['NFI Image Outer Req'], 'fname': 'NFI Image Outer Count Requested' }, 'Mac': { 'dbData': telemetryDictionary['NFI Image Marco'], 'fname': 'NFI Image Marco' } } } },

    { id: 'COSMO Htr', type: 'heater', parentNode: '', position: { x: 2400, y: 119 }, data: { name: 'COSMO Heater', className: "cosmoHeater", power: telemetryDictionary['GISSMO Survival Heater'], value: { 'E': { 'dbData': telemetryDictionary['GISSMO HTR Enabled'], 'fname': 'GISSMO Heater Enabled' }, 'SSP': { 'dbData': telemetryDictionary['GISSMO HTR Survival Set Point'], 'fname': 'COSMO Heater Survival Set Point' }, 'OSP': { 'dbData': telemetryDictionary['GISSMO HTR Operational Set Point'], 'fname': 'COSMO Heater Operational Set Point' }, 'OT': { 'dbData': telemetryDictionary['GISSMO Opht Temperature'], 'fname': 'COSMO Operational Heater Temperature' } } } },

    { id: 'WFI HVPS', type: 'hTextbox', parentNode: 'WFI Module', position: { x: 25, y: 110 }, data: { power: telemetryDictionary['WFI HV'], pwrName: 'WFI HV', name: 'WFI HVPS', className: 'hvpsContainer', value: { 'HV Temp': { 'dbData': telemetryDictionary['WFI HV Temp'], 'fname': 'High Voltage Temperature' }, 'HV13 Vmon': { 'dbData': telemetryDictionary['WFI HV13 Vmon'], 'telemetry': 20, 'unit': 'C' }, 'Phos IMon': { 'dbData': telemetryDictionary['WFI Phos IMon'], 'fname': 'Phosphor IMon' }, 'MCP IMon': { 'dbData': telemetryDictionary['WFI MCP IMon'], 'telemetry': 1.55, 'unit': 'uA' }, 'Phos VMon': { 'dbData': telemetryDictionary['WFI Phos VMon'], 'telemetry': 177.3, 'unit': 'V', 'fname': 'Phosphor VMon' }, 'MCP VMon': { 'dbData': telemetryDictionary['WFI MCP VMon'], 'telemetry': 82.6, 'unit': 'V' }, 'Phos Sel': { 'dbData': telemetryDictionary['WFI Phos Sel'], 'telemetry': 'SAFE', 'fname': 'Phosphor Sel' }, 'MCP Sel': { 'dbData': telemetryDictionary['WFI MCP Sel'], 'telemetry': 'SAFE' },/*'Phos Day':{'dbData':telemetryDictionary['WFI Phos Day'],'fname':'Phosphor Day'},'MCP Day':{'dbData':telemetryDictionary['WFI MCP Day'],'telemetry':2.6,'unit':'V'},*/'Phos Night': { 'dbData': telemetryDictionary['WFI Phos Night'], 'telemetry': 7.3, 'unit': 'V', 'fname': 'Phosphor Night' }, 'MCP Night': { 'dbData': telemetryDictionary['WFI MCP Night'], 'telemetry': 2.6, 'unit': 'V' }, 'Phos Safe': { 'dbData': telemetryDictionary['WFI Phos Safe'], 'telemetry': 7.3, 'unit': 'V', 'fname': 'Phosphor Safe' }, 'MCP Safe': { 'dbData': telemetryDictionary['WFI MCP Safe'], 'telemetry': 2.6, 'unit': 'V' } } } },
    { id: 'NFI HVPS', type: 'hTextbox', parentNode: 'NFI Module', position: { x: 25, y: 110 }, data: { power: telemetryDictionary['NFI HV'], pwrName: 'HFI HV', name: 'NFI HVPS', className: 'hvpsContainer', value: { 'HV Temp': { 'dbData': telemetryDictionary['NFI Temp'], 'fname': 'High Voltage Temperature' }, 'HV13 Vmon': { 'dbData': telemetryDictionary['NFI HV13 VMon'], 'telemetry': 19.2, 'unit': 'C' }, 'Phos IMon': { 'dbData': telemetryDictionary['NFI Phos IMon'], 'fname': 'Phosphor IMon' }, 'MCP IMon': { 'dbData': telemetryDictionary['NFI MCP IMon'], 'telemetry': 1.55, 'unit': 'uA' }, 'Phos VMon': { 'dbData': telemetryDictionary['NFI Phos VMon'], 'telemetry': 177.3, 'unit': 'V', 'fname': 'Phosphor VMon' }, 'MCP VMon': { 'dbData': telemetryDictionary['NFI MCP VMon'], 'telemetry': 82.6, 'unit': 'V' }, 'Phos Sel': { 'dbData': telemetryDictionary['NFI Phos Sel'], 'telemetry': 'SAFE', 'fname': 'Phosphor Sel' }, 'MCP Sel': { 'dbData': telemetryDictionary['NFI MCP Sel'], 'telemetry': 'SAFE' },/*'Phos Day':{'dbData':telemetryDictionary['NFI Phos Day'],'fname':'Phosphor Day'},'MCP Day':{'dbData':telemetryDictionary['NFI MCP Day'],'telemetry':2.6,'unit':'V'},*/'Phos Night': { 'dbData': telemetryDictionary['NFI Phos Night'], 'telemetry': 7.3, 'unit': 'V', 'fname': 'Phosphor Night' }, 'MCP Night': { 'dbData': telemetryDictionary['NFI MCP Night'] }, 'Phos Safe': { 'dbData': telemetryDictionary['NFI Phos Safe'], 'telemetry': 7.3, 'unit': 'V', 'fname': 'Phosphor Safe' }, 'MCP Safe': { 'dbData': telemetryDictionary['NFI MCP Safe'], 'telemetry': 2.6, 'unit': 'V' } } } },

    { id: 'WFI General', type: 'valuebox', parentNode: 'WFI Module', position: { x: 50, y: 55 }, data: { name: 'WFI General', className: 'wfiGeneral', value: { 'PT': { 'dbData': telemetryDictionary['WFI General Pwr Temp'], 'fname': 'WFI Power Temperature' }, 'DT': { 'dbData': telemetryDictionary['WFI General Dig Temp'], 'fname': 'WFI Dig Temperature' } } } },
    { id: 'NFI General', type: 'valuebox', parentNode: 'NFI Module', position: { x: 50, y: 55 }, data: { name: 'NFI General', className: 'nfiGeneral', value: { 'PT': { 'dbData': telemetryDictionary['NFI General Pwr Temp'], 'fname': 'NFI Power Temperature' }, 'DT': { 'dbData': telemetryDictionary['NFI General Dig Temp'], 'fname': 'NFI Dig Temperature' } } } },

    { id: 'GCI Optics General ', type: 'valuebox', parentNode: 'GCI Optical Module', position: { x: 25, y: 50 }, data: { name: 'GCI General', className: 'gciOpticsGeneral', value: { 'BT': { 'dbData': telemetryDictionary['GCI General Bench Temp'], 'fname': 'GCI Bench Temp' }, 'ABT': { 'dbData': telemetryDictionary['GCI General Aft Bench Temp'], 'fname': 'GCI Aft Bench Temp' } } } },

    { id: 'COSMO', type: 'cosmo',parentNode:'', position: { x: 800, y: -25 }, data: { name: 'Carruthers Student Solar Monitor (COSMO)', className: 'cosmoContainer', power: telemetryDictionary['GISSMO power'], enable:telemetryDictionary['GISSMO Enable'], value: { 'Mode': { 'dbData': telemetryDictionary['GISSMO Mode'], 'telemetry': 'SCI' }, 'Temp': { 'dbData': telemetryDictionary['GISSMO Temp'] }, 'Pwr Imon': { 'dbData': telemetryDictionary['GISSMO Pwr IMon'], 'telemetry': 23, 'unit': 'mA', 'fname': 'GISSMO Power IMon' } } } },

    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:0, y:-175}, data:{name:'', playBackValue: {time: pbTime, loop: pbLoop}}},

  ];

  return nodes;
};
