import { decode } from "@msgpack/msgpack";
import { getTemplateSrv } from "@grafana/runtime";
import copy from 'copy-to-clipboard';
import { InnerData, LimitProp } from "./type";

export const nameToMne = {
  "NFI HV": "iop_hv_nfi",
  "NFI Temp": "iop_nfi_hv_temp",
  "NFI HV13 VMon": "iop_nfi_hv13_vmon",
  "NFI Phos IMon": "iop_nfi_phos_imon",
  "NFI MCP IMon": "iop_nfi_mcp_imon",
  "NFI Phos VMon": "iop_nfi_phos_vmon",
  "NFI MCP VMon": "iop_nfi_mcp_vmon",
  "NFI Phos Sel": "iop_hv_nfi_phos_sel",
  "NFI MCP Sel": "iop_hv_nfi_mcp_sel",
  "NFI Phos Day": "iop_hv_dac_nfi_phos_day",
  "NFI MCP Day": "iop_hv_dac_nfi_mcp_day",
  "NFI Phos Night": "iop_hv_dac_nfi_phos_night",
  "NFI MCP Night": "iop_hv_dac_nfi_mcp_night",
  "NFI Phos Safe": "iop_hv_dac_nfi_phos_safe",
  "NFI MCP Safe": "iop_hv_dac_nfi_mcp_safe",
  "NFI Cam Pwr": "iop_nfi_cam_pwr",
  "NFI Cam IMon": "iop_nfi_cam_pwr_imon",
  "NFI Image Comp": "iop_nfi_comp_mode",
  "NFI Image Inner": "iop_nfi_img_inner_count",
  "NFI Image Outer": "iop_nfi_img_outer_count",
  "NFI Image Inner Req": "iop_nfi_img_inner_count_req",
  "NFI Image Outer Req": "iop_nfi_img_outer_count_req",
  "NFI Image Marco": "iop_nfi_img_macro",
  "NFI Image State": "iop_nfi_img_state",
  "NFI Image Sec Since Last": "iop_nfi_sec_since_last_img",
  "NFI DLED Temp": "iop_nfi_dled_temp",
  "NFI General Pwr Temp": "iop_nfi_pwr_temp",
  "NFI General Dig Temp": "iop_nfi_dig_temp",
  "NFI Opt 1 Temp": "iop_nfi_optics_temp1",
  "NFI Opt 2 Temp": "iop_nfi_optics_temp2",
  "NFI Bus SW FW": "nfiwfifwrpw",
  "NFI Filter Position": "iop_motor_fw_pos_nfi",
  "NFI Motor Busy": "iop_motor_busy_nfi",
  "NFI Fw Temp": "iop_nfi_fw_temp",
  "NFI Door Position": "iop_gci_nfi_door",
  "WFI HV": "iop_hv_wfi",
  "WFI HV Temp": "iop_wfi_hv_temp",
  "WFI HV13 Vmon": "iop_wfi_hv13_vmon",
  "WFI Phos IMon": "iop_wfi_phos_imon",
  "WFI MCP IMon": "iop_wfi_mcp_imon",
  "WFI Phos VMon": "iop_wfi_phos_vmon",
  "WFI MCP VMon": "iop_wfi_mcp_vmon",
  "WFI Phos Sel": "iop_hv_wfi_phos_sel",
  "WFI MCP Sel": "iop_hv_wfi_mcp_sel",
  "WFI Phos Day": "iop_hv_dac_wfi_phos_day",
  "WFI MCP Day": "iop_hv_dac_wfi_mcp_day",
  "WFI Phos Night": "iop_hv_dac_wfi_phos_night",
  "WFI MCP Night": "iop_hv_dac_wfi_mcp_night",
  "WFI Phos Safe": "iop_hv_dac_wfi_phos_safe",
  "WFI MCP Safe": "iop_hv_dac_wfi_mcp_safe",
  "WFI Cam Pwr": "iop_wfi_cam_pwr",
  "WFI Cam IMon": "iop_wfi_cam_pwr_imon",
  "WFI Comp": "iop_wfi_comp_mode",
  "WFI Image Inner": "iop_wfi_img_inner_count",
  "WFI Image Outer": "iop_wfi_img_outer_count",
  "WFI Image Inner Req": "iop_wfi_img_inner_count_req",
  "WFI Image Outer Req": "iop_wfi_img_outer_count_req",
  "WFI Image Marco": "iop_wfi_img_macro",
  "WFI Image State": "iop_wfi_img_state",
  "WFI Image Sec Since Last": "iop_wfi_sec_since_last_img",
  "WFI DLED Temp": "iop_wfi_dled_temp",
  "WFI General Pwr Temp": "iop_wfi_pwr_temp",
  "WFI General Dig Temp": "iop_wfi_dig_temp",
  "WFI Opt 1 Temp": "iop_wfi_optics_temp1",
  "WFI Opt 2 Temp": "iop_wfi_optics_temp2",
  "WFI Bus SW FW": "nfiwfifwrpw",
  "WFI Filter Position": "iop_motor_fw_pos_wfi",
  "WFI Fw Temp": "iop_wfi_fw_temp",
  "WFI Motor Busy": "iop_motor_busy_wfi",
  "WFI Door Position": "iop_gci_wfi_door",
  "GCI General Power": "iop_gci_pwr",
  "GCI General Pwr IMon": "iop_gci_pwr_imon",
  "GCI General Opht IMon": "iop_opht_imon",
  "GCI General Bench Temp": "iop_bench_for_temp",
  "GCI General Aft Bench Temp": "iop_bench_aft_temp",
  "GCI General DMA State": "iop_gci_dma_state",
  "GCI General TLM State": "iop_gci_tlm_state",
  "General DLED Pwr": "iop_dled_pwr",
  "HV DLED": "iop_hv_dled",
  "DLED Pwr Mux": "iop_dled_pwr_mux",
  "Fw Pwr IMon": "iop_fw_pwr_imon",
  "PMD Temp": "iop_pmd_temp",
  "Bus SW Door": "nfiwfiactpw",
  "Door Act Select": "iop_act_select",
  "Door Act Armed": "iop_act_armed",
  "Door Act Firing": "iop_act_firing",
  "GISSMO Enable": "iop_gissmo_ena",
  "GISSMO Mode": "iop_gissmo_mode",
  "GISSMO power": "iop_gissmo_pwr",
  "GISSMO Pwr IMon": "iop_gissmo_pwr_imon",
  "GISSMO Temp": "iop_gissmo_temp",
  "Bus SW ICP A": "icpapw",
  "Bus SW ICP B": "icpbpw",
  "ICP Core Temp": "iop_icp_core_temp",
  "ICP PSM Temp": "iop_psm_temp",
  "ICP DCB Temp": "iop_dcb_temp",
  "ICP FPGA Temp": "iop_fpga_temp",
  "ICP Pwr IMon": "iop_icp_pwr_imon",
  "ICP P5VA Vmon": "iop_scaled_p5va",
  "ICP P5VD Vmon": "iop_scaled_p5vd",
  "ICP P3V3D Vmon": "iop_scaled_p3v3d",
  "ICP moabp3p3v Vmon": "moabp3p3v",
  "ICP Vref 4P5V Vmon": "iop_vref_4p5v",
  "ICP Vref 2_4p5v Vmon": "iop_vref2_4p5v",
  "Pwr Therm lock": "iop_pwr_therm_interlock",
  "LVPS Temp": "iop_lvps_temp",
  "Instrument TLM": "icpcmdside",
  "Command Interface": "icptlmside",


  "Bench Survival Heater": "htrbenchfwdpw",
  "NFI Cam Survival Heater": "htrnficampw",
  "WFI Cam Survival Heater": "htrwficampw",
  "GISSMO Survival Heater": "htrgssmpw",

  "Bench Forward HTR Enabled": "htrbenchfwdst",
  "NFI CAM HTR Enabled": "htrnficamst",
  "WFI CAM HTR Enabled": "htrwficamst",
  "GISSMO HTR Enabled": "htrgssmst",

  "Bench Forward HTR Survival Set Point": "htrbenchfwdspsrv",
  "NFI Cam HTR Survival Set Point": "htrnficamspsrv",
  "WFI Cam HTR Survival Set Point": "htrwficamspsrv",
  "GISSMO HTR Survival Set Point": "htrgssmspsrv",

  "Bench Forward HTR Operational Set Point": "htrbenchfwdspop",
  "NFI Cam HTR Operational Set Point": "htrnficamspop",
  "WFI Cam HTR Operational Set Point": "htrwficamspop",
  "GISSMO HTR Operational Set Point": "htrgssmspop",
  
  "PMD Opht Temp": "iop_pmd_op_htr_temp",
  "NFI Cam Opht Temp": "iop_nfi_cam_op_htr_temp",
  "WFI Cam Opht Temp": "iop_wfi_cam_op_htr_temp",
  "GISSMO Opht Temperature": "iop_gsm_op_htr_temp",

  "Board ID": "iop_board_id",
  "FSW major": "iop_op_ver_major",
  "FSW minor": "iop_op_ver_minor",
}

export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){
  if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
    return 'red-limit';
  }
  else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
      return 'yellow-limit';
  }else{
    return 'gray-limit';
  }
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value.toLowerCase() === mne.toLowerCase()) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export function generateMneString() {
  return Object.values(nameToMne)
    .map((value) => `mnes/\${ScName}/${value}/base64`)
    .join(';');
}

export const decodeFunc = (data: string) => {

  if (!data || data === "") {
    return null;
  }

  const binaryString = window.atob(data);
        
  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  // Decode the MessagePack data
  const decodedObject = decode(bytes);
  
  return decodedObject;
}

const variableName = 'ScName'; // Replace with your Grafana variable
export const source = getTemplateSrv().replace(`\${${variableName}}`);

export const handleCopyMneName = (mne: string) => {
  copy(mne);
};

export function determineLimit(stringValue: string, limit: LimitProp){

  const value = parseFloat(stringValue);

  if (limit.rl !== null && value < limit.rl){
    return 'rl';
  }else if (limit.rh !== null && value > limit.rh){
    return 'rh';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'rl';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'rh';
  }
  else {
    return "";
  }
}

export const checkOutOfLimitsEx = (data: InnerData) => {
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `rgb(246, 80, 80)`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#CA51EC`;
  }else {
    return `black`;
  }
};

export const checkOutOfLimitsHid = (data: InnerData) => {
  //console.log(data);
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `#d10808`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#A020F0`;
  }
  else {
    return `gray`;
  }
};

