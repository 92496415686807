import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {
    
    const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeColor = data && data.power && data.power.telemetry === 'ON' ? 'green' : 'white';

  const edgeStyle = {
    ...style,
    stroke: edgeColor,
  }

  let finaledge;

  //const edge1 = `M480 575.5L 510,575.5Q 520,575.5 520,565.5L 520,300Q 520,290 530,290L 2365.5,290Q 2375.5,290 2375.5,300L2375.5 571`;
  if (id === 'HVPS edge0'){
    //(edgePath);
    const edge = `M${sourceX} ${sourceY}L${sourceX},${sourceY-125}Q ${sourceX},${sourceY-130} ${sourceX+5},${sourceY-130}L ${targetX-5},${sourceY-130}Q ${targetX},${sourceY-130} ${targetX},${sourceY-125}L${targetX} ${sourceY-130}L${targetX} ${targetY}`;

    finaledge = edge;
  }else if (id === 'HVPS edge-11'){
    const edge = `M${sourceX} ${sourceY}L${sourceX},${sourceY-105}Q ${sourceX},${sourceY-110} ${sourceX+5},${sourceY-110}L ${targetX-5},${sourceY-110}Q ${targetX},${sourceY-110} ${targetX},${sourceY-105}L${targetX} ${sourceY-110}L${targetX} ${targetY}`;
    finaledge = edge;

  }else if (id === 'Camera edge0'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX-115},${sourceY}Q ${sourceX-120},${sourceY} ${sourceX-120},${sourceY-5}L ${sourceX-120},${targetY-145}Q ${sourceX-120},${targetY-150} ${sourceX-115},${targetY-150}L ${targetX-5},${targetY-150}Q ${targetX},${targetY-150} ${targetX},${targetY-145}L${targetX} ${targetY}`
    finaledge = edge;
  }else if (id === 'Camera edge-11'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX-125},${sourceY}Q ${sourceX-130},${sourceY} ${sourceX-130},${sourceY-5}L ${sourceX-130},${targetY-155}Q ${sourceX-130},${targetY-160} ${sourceX-125},${targetY-160}L ${targetX-5},${targetY-160}Q ${targetX},${targetY-160} ${targetX},${targetY-155}L${targetX} ${targetY}`
    finaledge = edge;
  }
  else{
    finaledge = edgePath;
  }

  return (
    <>
      <BaseEdge path={finaledge} markerStart={markerStart} markerEnd={markerEnd} style={edgeStyle} />
      <EdgeLabelRenderer>
        <div style={{
            position: 'absolute',
            transform: `translate(-50%, -100%) translate(${labelX}px,${labelY}px)`,
          }}
          className="nodrag nopan customEdge">

        </div>
      </EdgeLabelRenderer>
    </>
  );

}
