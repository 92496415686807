import React, { useState } from "react";
import * as heaterScheme from './HeaterScheme'; // Import your styles
import { Handle, Position } from "reactflow";
import PlotlyChart from "./PlotlyChart";
import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styles
import { handleCopyMneName, determineColorNew } from "utils/function";
import { InnerData, TLM } from "utils/type";

interface HeaterProp {
  data: {
    name: string;
    value: InnerData;
    power: TLM;
    className: string;
  };
}

type VisibleTooltips = { [key: string]: boolean };

const Heater: React.FC<HeaterProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key]
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 2000);
  };

  const getColor1 = (value: TLM) => {
    if (!value) {
      return "rgb(100,100,100)";
    }
    switch (value.telemetry) {
      case "ON":
        return "rgb(13, 84, 7)";
      case "OFF":
        return "rgb(100,100,100)";
      default:
        return "rgb(100,100,100)"; // default color for null or undefined
    }
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const heaterContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `

  return (
    <div className="">
      <Handle type="target" position={Position.Top} id="target-top"  style={{opacity:0}} />
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}  />
      <Handle type="target" position={Position.Left} id="target-left-1" style={{ top: 20,opacity:0 }}/>
      <Handle type="target" position={Position.Left} id="target-left-2" style={{ top: 20,left:50, opacity:0 }}/>
      <Handle type="target" position={Position.Right} id="target-right"  style={{opacity:0}} />
      <Handle type="target" position={Position.Right} id="target-right-1" style={{ top: 20,opacity:0 }}/>
      <Handle type="target" position={Position.Right} id="target-right-2" style={{ top: 20,right:50,opacity:0 }}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom"  style={{opacity:0}} />
      <Handle type="source" position={Position.Top} id="source-top"  style={{opacity:0}} />
      <Handle type="source" position={Position.Left} id="source-left"  style={{opacity:0}} />
      <Handle type="source" position={Position.Right} id="source-right"  style={{opacity:0}} />
      <Handle type="source" position={Position.Bottom} id="source-bottom"  style={{opacity:0}} />

      <div className={cx(heaterContainer, heaterScheme[data.className as heaterScheme.ClassKey] )}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="322px" height="42px" viewBox="-0.5 -0.5 322 42">
            <defs />
            <g>
              <rect x="80" y="0" width="160" height="40" fill="rgb(38,38,38)" stroke="orange" strokeWidth="6" pointerEvents="all" />
              <path d="M 120 40 L 120 0" fill="rgb(38,38,38)" stroke="orange" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke" />
              <path d="M 159.8 40 L 159.8 0" fill="rgb(38,38,38)" stroke="orange" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke" />
              <path d="M 200 40 L 200 0" fill="rgb(38,38,38)" stroke="orange" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke" />
              <path d="M 243 19.76 L 320 20" fill="rgb(38,38,38)" stroke={getColor1(data.power)} strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke" />
              <path d="M 0 19.66 L 77 19.9" fill="rgb(38,38,38)" stroke={getColor1(data.power)} strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke" />
            </g>
          </svg>

          <span>{data.name}</span>

          <div className={scheme.svgValueColCon}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
        </div>

      </div>

    </div>
  );
};

export default Heater;
