import { css } from "@emotion/css"

export const nfiGeneral = css`
display: flex;
flex-direction: row;
width: 900px;
justify-content: space-evenly;
align-items: center;
`

export const wfiGeneral = css`
display: flex;
flex-direction: row;
width: 900px;
justify-content: space-evenly;
align-items: center;
`

export const gciGeneral = css`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
    justify-content: space-evenly;
    align-items: center;
`

export const gciOpticsGeneral = css`
    display: flex;
    flex-direction: row;
    width: 650px;
    height: 50px;
    justify-content: space-between;
    align-items: center;
`

export type ClassKey = 'nfiGeneral' | 'wfiGeneral' | 'gciGeneral' | 'gciOpticsGeneral';
