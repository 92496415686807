import React from 'react'
import { css } from '@emotion/css';

const GCI_Imager: React.FC = () => {

  return (
    <div className={css`
    width: 2200px;
    height: 2175px;
    /* border: 5px dashed rgb(33, 110, 224); */
    position: relative;
    `}>

      <p className={css`
          position: absolute;
          top: 33%;
          left: 47%;
          transform: translate(-50%, -50%);
          `}
          >GeoCoronal Imager</p>
      
    </div>
  )
}

export default GCI_Imager;
