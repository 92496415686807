import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import { css, cx } from '@emotion/css';
import { handleCopyMneName, determineColorNew } from 'utils/function';
import { TLM, InnerData } from 'utils/type';

import * as scheme from './Scheme'; // Import your sty
import * as htbScheme from './HTextboxScheme'; // Import your style

type VisibleTooltips = { [key: string]: boolean };

interface CosmoProp {
  data: {
    name: string;
    value: InnerData;
    className: string;
    pripwr: TLM;
    enable: TLM;
  };
}

const svgContainer = css`
display: flex;
justify-content: space-between;
padding: 0px 10px; 
height: 55px;
`;

const svgInnerContainer = css`
display: flex;
`;

const Cosmo: React.FC<CosmoProp> = ({ data }) => {
  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips((prevVisibleTooltips) => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key],
    }));

    setTimeout(() => {
      setVisibleTooltips((prevVisibleTooltips) => ({
        ...prevVisibleTooltips,
        [key]: false,
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops((prevVisibleLimitPops) => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key],
    }));
  };

  const powerClass =
    data && data.pripwr && (data.pripwr.telemetry === '1' || data.pripwr.telemetry === 'ON') ? scheme.powerClass : '';

    const getColorEnable = (telemetry: TLM) => {
      if (!telemetry || !telemetry.telemetry){
        return "rgb(100,100,100)";
      }
    
      if (telemetry.telemetry.toLowerCase() === 'ENABLED'.toLowerCase()){
          return "rgb(72, 200, 44)";
      }
      else{
        return "rgb(100,100,100)";
      }
    }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right-1" style={{ opacity: 0, top: 163 }} />

      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />

      <div>
        <svg
          fill="rgb(56,56,56)"
          width="1050px"
          height="80px"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-.5 -0.5 1200 80"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <line x1="150" y1="80" x2="110" y2="0" stroke="white" strokeWidth="6"/>
          <line x1="185" y1="80" x2="225" y2="0" stroke="white" strokeWidth="6"/>

          <line x1="550" y1="80" x2="510" y2="0" stroke="white" strokeWidth="6"/>
          <line x1="585" y1="80" x2="625" y2="0" stroke="white" strokeWidth="6"/>

          <line x1="950" y1="80" x2="910" y2="0" stroke="white" strokeWidth="6"/>
          <line x1="985" y1="80" x2="1025" y2="0" stroke="white" strokeWidth="6"/>

        </svg>
      </div>
      
      <div className={cx(scheme.textboxContainer, htbScheme[data.className as htbScheme.ClassKey], powerClass)}>

<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="55px" height="55px" viewBox="-0.5 -0.5 55 55"
className={css`
position: absolute;
top: 5px;
left: 5px;
`}>
  <g>
    <g>
    <rect x="5" y="5" width="47" height="47" fill="rgb(38,38,38)" stroke={getColorEnable(data.enable)} pointerEvents="all" strokeWidth="6"/>
    </g>
    <g>
      <text x="28" y="40" fill={getColorEnable(data.enable)} fontFamily="Times New Roman" fontSize="35px" textAnchor="middle">En
      </text>
    </g>
  </g>
</svg> 
        <p>{data.name}</p>

        <div className={scheme.tbHorContainer}>
          {data.value &&
            Object.entries(data.value).map(([key, innerData]) => (
              <div className={scheme.tbValueContainer} key={key}>
                <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                  {key}:
                  {visibleTooltips[key] && (
                    <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>
                      {innerData.dbData.mne}
                    </span>
                  )}
                  <span data-comp="fname" className={scheme.tbFnameTooltip}>
                    {innerData.fname}
                  </span>
                </div>
                <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                  <span
                    className={cx(
                      scheme.value,
                      css`
                        color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ''};
                      `,
                      scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                    )}
                  >
                    {innerData.dbData?.telemetry}
                  </span>
                  {innerData.dbData && innerData.dbData.unit && (
                    <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>
                  )}
                </div>
                {visibleLimitPops[key] && (
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                      Close Plot
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Cosmo;
