
import {css} from "@emotion/css";

export const camContainer = css`
width: 300px;

& > div {

  & > div {
    width: 300px;
    justify-content: space-between;

    &:nth-of-type(n+3):nth-of-type(-n+8) {
        width: 140px;
    }
  }
}
`

export type ClassKey = 'camContainer'
