import React from 'react'
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';

const Bench: React.FC = () => {

  const gciOpticalContainer = css`
  width: 2850px;
  height: 2250px;
  background-color: rgb(38, 38, 38);
  border: 6px solid rgb(13, 57, 152);
  text-align: left;
  `

  return (
    <div className={gciOpticalContainer}>

      <Handle type="target" position={Position.Right} id="target-right" style={{right:-450, top: 100, opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}} />
      <Handle type="source" position={Position.Top} id="source-top"  style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{left:0, top: 300, opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}} />
      <Handle type="source" position={Position.Bottom} id="source-bottom"  style={{opacity:0}}/>

      <p className='gci-optic-span-title'>GeoCoronal Optical Bench</p>
    </div>
  )
}

export default Bench;
