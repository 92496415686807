import React from 'react';
import { css } from '@emotion/css';

const NFI: React.FC = () => {

  const nfiContainer = css`
    width: 975px;
    height: 1950px;
    border-top: 5px dashed rgb(33, 110, 224);
    border-left: 5px dashed rgb(33, 110, 224);
    position: relative;

    &::before {
      content: "";
      width: 650px;
      height: 1350px;
      position: absolute;
      left: 0;
      bottom: 0;
      border-right: 5px dashed rgb(33, 110, 224);
      border-bottom: 5px dashed rgb(33, 110, 224);
    }

    &::after {
      content: "";
      width: 325px;
      height: 575px;
      position: absolute;
      right: 0;
      top: 0;
      border-right: 5px dashed rgb(33, 110, 224);
      border-bottom: 5px dashed rgb(33, 110, 224);
    }
  `;

  return (
    <div className={nfiContainer}>
      <p>Narrow Field Imager (NFI)</p>
    </div>
  );
};

export default NFI;
