import { css } from '@emotion/css';

export const powerClass = css`
border: green 5px solid;`;

export const textboxContainer = css`
  width: 500px;
  height: max-content;
  color: white;
  border: 5px solid rgb(104, 176, 253);
  position: relative; 
  background-color: black; 
  overflow: visible; 
`;

export const textboxItems = css`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const tbHorContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
`

export const tbValueContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  white-space: nowrap;
  position: relative;
`;

export const tbValueSpanContainer = css`
  box-sizing: content-box;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;  
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: rgb(255, 255, 255) 5px solid;
  overflow: visible;
  background-color: rgb(255, 255, 255);
`;



export const value = css`
  width: 100%;
  height: 100%;
  line-height: 40px;
  background-color: rgb(28, 42, 59);
  color: rgb(68, 169, 241);
`;

export const unit = css`
  width: 30%;
  height: 100%;
  line-height: 40px;
  background-color: rgb(255,255,255);
  color: black;
`;

export const line = css`
  display: flex;
  justify-items: center;
  gap: 10px;
`;

export const redLimit = css`
  color: rgb(246, 80, 80); 
`;

export const yellowLimit = css`
  color: rgb(230, 230, 65); 
`;


export const svgValueSpanContainer = css`
  background-color: rgb(255, 255, 255);
  box-sizing: content-box;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;  
  width: 250px;
  height: 40px;
  border-radius: 5px;
  border: rgb(255, 255, 255) 5px solid;
`;


export const svgValue = css`
  width: 100%;
  height: 100%;
  line-height: 40px;
  background-color: rgb(28, 42, 59);
  color: rgb(68, 169, 241);
`;

export const svgUnit = css`
  width: 30%;
  height: 100%;
  line-height: 40px;
  background-color: rgb(255,255,255);
  color: black;
`;

export const svgText = css`
  width: max-content;
  color: black;
  text-align: center;
  position: absolute;
  background-color: gray;
  padding: 0px 5px;
  border-radius: 10px;
  top: -10%;
  left: -70%;
  z-index: 100;
  overflow: visible;
  font-size: 30px;
  `;

  export const svgPopValue = css`
  position: absolute;
  top: -31%;
  left: -50%;
  z-index: 100;
  overflow: visible;
  font-size: 30px;
  `;

export const svgKey = css`
  width: 40%;
  height: 100%;
  line-height: 40px;
  background-color: rgb(28, 42, 59);
  border-right: rgb(255, 255, 255) 5px solid;
  color: white;
`;

export const FnameKeyContainer = css`
&:hover [data-comp="fname"] {
  display: block;
}
`;

export const tbFnameTooltip = css`
  color: black;
  position: absolute;
  background-color: gray;
  padding: 0px 15px;
  border-radius: 10px;
  top: -100%;
  left: 0%;
  z-index: 1000;
  overflow: visible;
  display: none;
`;

export const svgFnameTooltip = css`
  width: max-content;
  color: black;
  text-align: center;
  position: absolute;
  background-color: gray;
  padding: 0px 15px;
  margin: -5% 0%;
  border-radius: 10px;
  top: -100%;
  left: 0%;
  z-index: 1000;
  overflow: visible;
  display: none;
`;

export const svgValueContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
`

export const svgValueColCon = css`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const live = css`
  color: rgb(72, 200, 44);
`;

export const mnameTooltip = css`
  width: max-content;
  color: black;
  text-align: center;
  position: absolute;
  background-color: gray;
  border-radius: 10px;
  padding: 0px 15px;
  top: 0%;
  left: 0%;
  z-index: 1000;
  overflow: visible;
`;

export const chartContainer = css`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2001;
`;

export const plotButton = css`
  position: absolute;
  top: 20px;
  right: 20px;
  color: black;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

export const classMap: { [key: string]: any } = {
  'red-limit': redLimit,
  'yellow-limit': yellowLimit,
  'live': live
};

export const titleContainer = css`

  p {
    padding: 0;
    margin: 0;
  }
`;
