import React, { useState } from "react";
import * as camScheme from './CameraScheme'; // Import your styles
import { Handle, Position} from "reactflow";
import PlotlyChart from "./PlotlyChart";
import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styles
import { determineColorNew, handleCopyMneName } from "utils/function";
import { TLM, InnerData, VisibleTooltips } from "utils/type";

interface CamProp {
  data: {
    name: string;
    power: TLM;
    unit: string;
    value: InnerData;
    state: TLM;
    className: string;
  };
}

const Camera: React.FC<CamProp> = ({ data }) => {

  const [showPowerInfo, setShowPowerInfo] = useState(false);
  const [showStateInfo, setShowStateInfo] = useState(false);

  const handlePowerEllipseClick = () => {
    setShowPowerInfo(!showPowerInfo);

    //console.log('click power')

    setTimeout(() => {setShowPowerInfo(false)}, 5000);
  };
  
  const handleStateEllipseClick = () => {
    setShowStateInfo(!showStateInfo);
    //console.log('click state')

    setTimeout(() => {setShowStateInfo(false)}, 5000);
  };

  const getColor1 = (value: TLM) => {
    if (!value) {
      return "rgb(100,100,100)";
    }
    switch (value.telemetry) {
      case "ON":
        return "rgb(13, 84, 7)";
      case "OFF":
        return "rgb(100,100,100)";
      default:
        return "rgb(100,100,100)"; // default color for null or undefined
    }
  };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key]
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const getColor2 = (value: TLM) => {
    if (!value) {
      return "rgb(100,100,100)";
    }
    switch (value.telemetry) {
      case "RUNNING":
        return "rgb(13, 84, 7)";
      default:
        return "rgb(100,100,100)"; // default color for null or undefined
    }
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  //console.log('name', data.name);

  return (
    <div>
      {/* <Handle type="target" position={Position.Top} id="target-top" />
      <Handle type="target" position={Position.Left} id="target-left"/> */}
      <Handle type="target" position={Position.Right} id="target-right" style={{right:60, top: 100,opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{right:60, top: 120,opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}} />
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}  />
      <Handle type="source" position={Position.Left} id="source-left" style={{left:60, top: 120,opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{left:60, top: 120,opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-2" style={{left:0, top: 140,opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-3" style={{left:60,top: 140,opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right"  style={{opacity:0}} />
      <Handle type="source" position={Position.Right} id="source-right-2" style={{right:60, top: 100,opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"  style={{opacity:0}} />

      <div className={cx(camScheme[data.className as camScheme.ClassKey], css`position: relative`)}>
        
        {data.name === "WFI Camera" ? 
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="170px" height="170px" viewBox="-0.5 -0.5 170 170">
            <g>
              <rect x="5" y="5" width="160" height="160" rx="17.6" ry="17.6" fill="rgb(38,38,38)" stroke="purple" strokeWidth={6} pointerEvents="all" />
              <ellipse cx="85" cy="85" rx="60" ry="60" fill="rgb(38,38,38)" stroke="purple" strokeWidth={6} pointerEvents="all" />
              <ellipse cx="85" cy="85" rx="45" ry="45" fill={getColor2(data.state)} stroke="purple" strokeWidth={6} pointerEvents="all" onClick={handleStateEllipseClick} className={data.state && data.state.telemetry === "RUNNING" ? "runningState" : ""}/>
              {/* <ellipse cx="15" cy="15" rx="10" ry="10" fill={getColor1(data.power)} stroke="#FFFFFF" strokeWidth={3} pointerEvents="all" onMouseEnter={handlePowerMouseEnter} onMouseLeave={handlePowerMouseLeave}/>
              <ellipse cx="145" cy="15" rx="10" ry="10" fill={getColor2(data.comp)} stroke="#FFFFFF" strokeWidth={3} pointerEvents="all" onMouseEnter={handleCompMouseEnter} onMouseLeave={handleCompMouseLeave}/> */}
            </g>
          </svg>
           : 

        data.name === "NFI Camera" && 
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="170px" height="170px" viewBox="-0.5 -0.5 170 170">
            <defs />
            <g>
              <rect x="5" y="5" width="160" height="160" rx="17.6" ry="17.6" fill="rgb(38,38,38)" stroke="purple" strokeWidth={6} pointerEvents="all" />
              <ellipse cx="85" cy="85" rx="60" ry="60" fill="rgb(38,38,38)" stroke="purple" strokeWidth={6} pointerEvents="all" />
              <ellipse cx="85" cy="85.01" rx="30.125" ry="30.125" fill={getColor2(data.state)} stroke="purple" strokeWidth={6} pointerEvents="all" onClick={handleStateEllipseClick} className={data.state && data.state.telemetry === "RUNNING" ? "runningState" : ""} />
              {/* <ellipse cx="15" cy="15" rx="10" ry="10" fill={getColor1(data.power)} stroke="#FFFFFF" strokeWidth={3} pointerEvents="all" />
              <ellipse cx="145" cy="15" rx="10" ry="10" fill={getColor2(data.comp)} stroke="#FFFFFF" strokeWidth={3} pointerEvents="all" /> */}
            </g>
          </svg>}

        {data.power && <div style={{
            position: 'absolute',
            top: '0',
            left: '65px',
            width: '60px',
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="60px" height="60px" viewBox="-0.5 -0.5 60 60" onClick={handlePowerEllipseClick}>
            <g>
              <g>
                <ellipse cx="25" cy="25" rx="15" ry="15" stroke="rgb(38,38,38)" fill={getColor1(data.power)} pointerEvents="all" strokeWidth="6" />
              </g>
            </g>
          </svg>
          {<span className={cx(scheme.svgText, css`left: -70px`)}>Pwr</span>}
          {showPowerInfo && <div className={scheme.svgPopValue}>
            <div className={scheme.svgValueSpanContainer}>
              <span className={scheme.svgKey} onClick={() => handleMneNameClick("PriPower")}>
                HV
                <span className={scheme.svgFnameTooltip}>HV</span>
                {visibleTooltips['PriPower'] && <span className={scheme.mnameTooltip}>{data.power.mne}</span>}
              </span>
              <span
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.power.live}`,
                  scheme.classMap[determineColorNew(data.power.limit)]
                )}
              >
                {data.power.telemetry}
              </span>
            </div>
          </div>}
        </div>}

        {data.state && <div style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '60px',
          }}>
          {<span className={cx(scheme.svgText, css`top:60px`)}>State</span>}
          {showPowerInfo && <div className={scheme.svgPopValue}>
            <div className={scheme.svgValueSpanContainer}>
              <span className={scheme.svgKey} onClick={() => handleMneNameClick("State")}>
              State
                {visibleTooltips['State'] && <span className={scheme.mnameTooltip}>{data.state.mne}</span>}
              </span>
              <span
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.state.live}`,
                  scheme.classMap[determineColorNew(data.state.limit)]
                )}
              >
                {data.state.telemetry}
              </span>
            </div>
          </div>}
        </div>}

        <p>Camera</p>

        <div className={scheme.svgValueContainer}>
        {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
        
        </div>
      </div>
    </div>
  );
};

export default Camera;
