import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import PlotlyChart from "./PlotlyChart";
import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styles
import { handleCopyMneName, determineColorNew } from "utils/function";
import { TLM, InnerData } from "utils/type";

interface DoorProp {
  data: {
    position: TLM;
    value: InnerData;
    power: TLM;
    select: TLM;
    name: string;
  };
}

type VisibleTooltips = { [key: string]: boolean };

const Door: React.FC<DoorProp> = ({ data }) => {

  const isNFI = data.select && data.select.telemetry ? data.select.telemetry === "NFI_DOOR" : false;

  const flexContainerClass = css`
    display: flex;
    ${isNFI
      ? "flex-direction: row;"
      : "flex-direction: row-reverse;"};
      gap: 10px;
      justify-content: center;
      align-items: center;
    `

    const getColor1 = (value: TLM) => {
      if (!value) {
        return "rgb(100,100,100)";
      }
      switch (value.telemetry) {
        case "ON":
          return "rgb(13, 84, 7)";
        case "OFF":
          return "rgb(100,100,100)";
        default:
          return "rgb(100,100,100)"; // default color for null or undefined
      }
    };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key]
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 2000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const selectVar =  data.select && data.select.telemetry ? data.select.telemetry.toLowerCase().includes(data.name.toLowerCase()) : false;

  const posVar = data.position && data.position.telemetry ? data.position.telemetry.toLowerCase() === "OPENED" : false;

  return (
    <div className={flexContainerClass}>
      {/* <Handle type="target" position={Position.Top} id="target-top" /> */}
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{ top: 30, left: -30, opacity: 0 }} />

      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{ top: 30, right: -30, opacity: 0 }} />

      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width="352px"
          height="90px"
          viewBox="-0.5 -0.5 352 90"
        >
          <defs></defs>
          <g>
            <rect
              x="0"
              y="0"
              width="80"
              height="40"
              fill="rgb(255, 255, 255)"
              stroke="rgb(0, 0, 0)"
              pointerEvents="all"
            />
            <rect
              x="270"
              y="0"
              width="80"
              height="40"
              fill="rgb(255, 255, 255)"
              stroke="rgb(0, 0, 0)"
              pointerEvents="all"
            />
            <rect
              x="80"
              y="0"
              width="20"
              height="40"
              fill="rgb(56, 56, 56)"
              stroke="rgb(0, 0, 0)"
              pointerEvents="all"
            />
            <rect
              x="250"
              y="0"
              width="20"
              height="40"
              fill="rgb(56, 56, 56)"
              stroke="rgb(0, 0, 0)"
              pointerEvents="all"
            />

            {selectVar ? (
              <ellipse
                cx="310"
                cy="18"
                rx="15"
                ry="15"
                fill={getColor1(data.power)}
                stroke="black"
                pointerEvents="all"
              />) : (
              <></>)}

            {posVar ? (
              <rect
                x="95"
                y="30"
                width="150"
                height="10"
                fill="rgb(13, 84, 7)"
                stroke="rgb(0, 0, 0)"
                transform="rotate(-20,245,30)"
                pointerEvents="all"
              />) : (
              <rect
                x="100"
                y="20"
                width="150"
                height="10"
                fill="rgb(100, 100, 100)"
                stroke="rgb(0, 0, 0)"
                pointerEvents="all"
              />)}

          </g>
        </svg>

      </div>

      {selectVar && 

        <div className={scheme.textboxItems}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] &&
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
      </div>}
    </div>
  );
};

export default Door;
