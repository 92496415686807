import React, { useState } from 'react'
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styles
import * as tbScheme from './TBScheme'; // Import your styles
import { determineColorNew, handleCopyMneName } from 'utils/function';
import { TbProps, VisibleTooltips } from 'utils/type';

const TextBox: React.FC<TbProps> = ({ data }) => {

  const [showPowerInfo, setShowPowerInfo] = useState(false);
  const [showPowerInfo1, setShowPowerInfo1] = useState(false);
  // const [showStateInfo, setShowStateInfo] = useState(false);

  const handlePowerEllipseClick = () => {
    setShowPowerInfo(!showPowerInfo);

    setTimeout(() => { setShowPowerInfo(false) }, 2000);
  };

  const handlePowerEllipseClick1 = () => {
    setShowPowerInfo1(!showPowerInfo1);

    setTimeout(() => { setShowPowerInfo1(false) }, 2000);
  };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key]
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const getColor = (power: string) => {
    if (power === "ON") {
      return "rgb(13, 84, 7)"; // Green for the selected and ON disk
    } else if (power === "OFF") {
      return "rgb(100,100,100)"; // Default gray color
    }
    else {
      return "rgb(100,100,100)";
    }
  }

  //console.log(data.name, data.power.telemetry)
  
  const powerClass = (data && data.power && (data.power.telemetry === '1' || data.power.telemetry === 'ON')) ? scheme.powerClass : '';

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top"  style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{ left: 308, opacity: 0 }} />
      <Handle type="target" position={Position.Top} id="target-top-2" style={{ left: 298, opacity: 0 }} />

      <Handle type="target" position={Position.Left} id="target-left"  style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{ top: 110, opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left-2" style={{ top: 200, opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left-3" style={{ top: 220, opacity: 0 }} />

      <Handle type="target" position={Position.Right} id="target-right-1" style={{ top: 110, opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right"  style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-3" style={{ top: 140, opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right-4" style={{ top: 200, opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right-5" style={{ top: 220, opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right-6" style={{ top: 160, opacity: 0 }} />

      <Handle type="target" position={Position.Bottom} id="target-bottom" />
      <Handle type="target" position={Position.Bottom} id="target-bottom-1" style={{ left: 1100, opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom-2" style={{ left: 1120, opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom-3" style={{ left: 200, opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom-4" style={{ left: 320, opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom-5" style={{ left: 230, opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom-6" style={{ left: 290, opacity: 0 }} />

      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>

      <Handle type="source" position={Position.Left} id="source-left"  style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{ right: 0, top: 110, opacity: 0 }} />

      <Handle type="source" position={Position.Right} id="source-right"  style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{ right: 0, top: 110, opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right-2" style={{ right: 0, top: 45, opacity: 0 }} />

      <Handle type="source" position={Position.Bottom} id="source-bottom"  style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom-1" style={{ left: 308, opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom-2" style={{ left: 297.5, opacity: 0 }} />

      <div className={cx(scheme.textboxContainer, tbScheme[data.className as tbScheme.ClassKey], powerClass)}>

        {data.power && <div className={css`position: absolute; 
      `}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="60px" height="60px" viewBox="-0.5 -0.5 60 60" onClick={handlePowerEllipseClick}>
            <g>
              <g>
                <ellipse cx="25" cy="25" rx="15" ry="15" stroke="rgb(38,38,38)" fill={getColor(data.power.telemetry)} pointerEvents="all" strokeWidth="6" />
              </g>
            </g>
          </svg>
          {<span className={cx(scheme.svgText, css`top:-60%`)}>{data.powerName ? data.powerName : 'Bus SW ICP A'}</span>}
          {showPowerInfo && <div className={scheme.svgPopValue}>
            <div className={scheme.svgValueSpanContainer}>
              <span className={scheme.svgKey} onClick={() => handleMneNameClick("PriPower")}>
                Pri
                <span className={scheme.svgFnameTooltip}>Pri Power</span>
                {visibleTooltips['PriPower'] && <span className={scheme.mnameTooltip}>{data.power.mne}</span>}
              </span>
              <span
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.power.live}`,
                  scheme.classMap[determineColorNew(data.power.limit)]
                )}
              >
                {data.power.telemetry}
              </span>
            </div>
          </div>}
        </div>}

        {data.power1 && <div className={css`position: absolute;
            right: 0;
      `}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="60px" height="60px" viewBox="-0.5 -0.5 60 60" onClick={handlePowerEllipseClick1}>
            <g>
              <g>
                <ellipse cx="25" cy="25" rx="15" ry="15" stroke="rgb(38,38,38)" fill={getColor(data.power1.telemetry)} pointerEvents="all" strokeWidth="6" />
              </g>
            </g>
          </svg>
          {<span className={cx(scheme.svgText, css`top:-60%`)}>Bus SW ICP B</span>}
          {showPowerInfo1 && <div className={scheme.svgPopValue}>
            <div className={scheme.svgValueSpanContainer}>
              <span className={scheme.svgKey} onClick={() => handleMneNameClick("SecPower")}>
                Sec
                {visibleTooltips['SecPower'] && <span className={scheme.mnameTooltip}>{data.power1.mne}</span>}
              </span>
              <span
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.power1.live}`,
                  scheme.classMap[determineColorNew(data.power1.limit)]
                )}
              >
                {data.power1.telemetry}
              </span>
            </div>
          </div>}
        </div>}


        <p>{data.name}</p>

        <div className={scheme.textboxItems}>

          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key}>
              <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                <span
                  className={cx(
                    scheme.value,
                    css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)],
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] &&
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TextBox;
