import { css } from "@emotion/css";

export const svHeaterContainer = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`


export const cosmoHeater = css`
width: 420px;

  & > div {
    width:420px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    & > div {
      width: 190px;
    }
  }
`;

export type ClassKey =  'cosmoHeater'
