import { css } from '@emotion/css';

export const icpContainer = css`
  width: 550px;
  background-color: rgb(92, 60, 18);
`;

// Define a type for the class key
export type ClassKey = 'icpContainer'

