
import * as scheme from './Scheme'; // Import your sty
import * as htbScheme from './HTextboxScheme'; // Import your style
import { css, cx } from '@emotion/css';

import React, { useState } from 'react'
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import { determineColorNew, handleCopyMneName } from 'utils/function';
import { InnerData, TLM, VisibleTooltips } from 'utils/type';

type HtbProps = {
  data: {
    name: string;
    value: InnerData;
    className: string;
    id: string;
    pripwr: TLM;
  };
};

const HTextbox: React.FC<HtbProps> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const powerClass = (data && data.pripwr && (data.pripwr.telemetry === '1' || data.pripwr.telemetry === 'ON')) ? scheme.powerClass : '';

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right-2" style={{ top: 227, opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right-1" style={{ top: 187, opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left-4" style={{ top: 150, opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ left: 150, opacity: 0 }} />

      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top-1" style={{ left: 475, opacity: 0 }} />

      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right-1" style={{ opacity: 0, top: 50 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

      <div className={cx(scheme.textboxContainer, htbScheme[data.className as htbScheme.ClassKey], powerClass)}>

        <p>{data.name}</p>
        <div className={scheme.tbHorContainer}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key}>
              <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                <span
                  className={cx(
                    scheme.value,
                    css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)],
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] &&
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HTextbox;
