import React, { useState } from "react";
import PlotlyChart from "./PlotlyChart";
import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styles
import { handleCopyMneName, determineColorNew } from "utils/function";
import { InnerData, VisibleTooltips } from "utils/type";

interface OpticsProp {
  data: {
    name: string;
    position: string;
    value: InnerData;
  };
}

const Optics: React.FC<OpticsProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const opticsValueCon = css`
  position: absolute;
  bottom: 10%;
  ${data.name.toLowerCase().includes('WFI'.toLowerCase()) ? 'right: 180px;' : 'left: 180px;'}
  `

  const opticsNameContainer = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    `

  return (
    <div className="">
      <div style={{
          position: "relative"
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="162px" height="451px" viewBox="-0.5 -0.5 161 521">
            <defs />
            <g>
              {/* <path d="M 0 31 C 0 13.88 35.82 0 80 0 C 101.22 0 121.57 3.27 136.57 9.08 C 151.57 14.89 160 22.78 160 31 L 160 469 C 160 486.12 124.18 500 80 500 C 35.82 500 0 486.12 0 469 Z" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" pointerEvents="all" />
    <path d="M 160 31 C 160 48.12 124.18 62 80 62 C 35.82 62 0 48.12 0 31" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" pointerEvents="all" /> */}
              <path d="M 0 41 C 0 23.88 35.82 10 80 10 C 101.22 10 121.57 13.27 136.57 19.08 C 151.57 24.89 160 32.78 160 41 L 160 479 C 160 496.12 124.18 510 80 510 C 35.82 510 0 496.12 0 479 Z"
                fill="rgb(38,38,38)" stroke="purple" strokeWidth="10" strokeMiterlimit="10" pointerEvents="all" />
              <path d="M 160 41 C 160 58.12 124.18 72 80 72 C 35.82 72 0 58.12 0 41" fill="rgb(38,38,38)" stroke="purple" strokeWidth="10" strokeMiterlimit="10" pointerEvents="all" />
            </g>
          </svg>

          <div className={opticsNameContainer}>
            Optics
          </div>


        <div className={cx(scheme.textboxItems, opticsValueCon)}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] &&
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Optics;
