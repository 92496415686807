
import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import PlotlyChart from './PlotlyChart';
import { handleCopyMneName, determineColorNew } from 'utils/function';
import { TLM, InnerData } from 'utils/type';

interface PMDProp {
  data: {
    temp: number;
    prwImon: TLM;
    nfiMotorPos: string;
    wfiMotorPos: string;
    unit: string;
    value: InnerData;

    prwImonUnit: string;
    motorPos: string;
    nfiMotorBusy: TLM;
    wfiMotorBusy: TLM;
  }
}

type VisibleTooltips = { [key: string]: boolean };

const PMD: React.FC<PMDProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key]
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 2000);
  };

  // const getColorNFI = () => {
  //   if (data.nfiMotorBusy.telemetry === "BUSY") {
  //       return "green"; // Green for the selected and ON disk
  //   } else {
  //       return "#FFFFFF"; // Default gray color
  //   }
  // }

  // const getColorWFI = () => {
  //   if (data.wfiMotorBusy.telemetry === "BUSY") {
  //       return "green"; // Green for the selected and ON disk
  //   } else {
  //       return "#FFFFFF"; // Default gray color
  //   }
  // }

  const motorFlex = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 3px solid white;
  `

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div className="">
      <Handle type="target" position={Position.Top} id="target-top" />
      <Handle type="target" position={Position.Top} id="target-top-1" style={{ left: 300 }} />
      <Handle type="target" position={Position.Left} id="target-left-top-1" style={{ top: 270, left: 30 }} />

      <Handle type="target" position={Position.Right} id="target-right" />
      <Handle type="source" position={Position.Top} id="source-top" />

      <Handle type="source" position={Position.Left} id="source-left" />

      <Handle type="source" position={Position.Right} id="source-right" />

      <Handle type="target" position={Position.Right} id="target-right-top-1" style={{ top: 217, background: "rgb(38,38,38)" }} />
      <Handle type="target" position={Position.Right} id="target-right-1" style={{ top: 237, background: "rgb(38,38,38)" }} />
      <Handle type="target" position={Position.Right} id="target-right-top-2" style={{ top: 270, right: 30 }} />

      <Handle type="target" position={Position.Bottom} id="target-bottom-left-1" style={{ left: 130, background: "rgb(38,38,38)" }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom-right-1" style={{ left: 350 }} />

      <Handle type="target" position={Position.Bottom} id="target-bottom-left-2" style={{ left: 90 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom-right-2" style={{ left: 390 }} />


      <div className={motorFlex}>

        <span>Preamp & Motor Box</span>
        <div>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>

              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`color: ${innerData.dbData?.live}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
          ))}
        </div>

        <div className={scheme.svgValueSpanContainer}>
          <span className={scheme.svgKey} onClick={() => handleMneNameClick("FWIMON")}>
            I
            <span className={scheme.svgFnameTooltip}>Filter Wheel Power IMon</span>
            {visibleTooltips['FWIMON'] && <span className="mname-tooltip">{data.prwImon.mne}</span>}
          </span>
          <span 
                onClick={() => handleValueChartClick('FWIMON')} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${data.prwImon?.live}`,
                  scheme.classMap[determineColorNew(data.prwImon?.limit)] 
                )}
              >
                {data.prwImon?.telemetry}
              </span>
              {data.prwImon && data.prwImon.unit && <span className={scheme.svgUnit}>{data.prwImon.unit}</span>}
        </div>
      </div>

      {/* <span>Preamp actuator board</span> */}
    </div>
  );
}

export default PMD;
