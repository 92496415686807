import { css } from '@emotion/css';

export const hvpsContainer = css`
width: 900px;

  & > div {
    width:900px;

    & > div {
      width: 440px;

      & div:nth-of-type(2) {
        width: 180px;
      }
    }
  }
`;

export const cosmoContainer = css`
width: 1075px;
padding-bottom: 5px;

  & > div {

    & > div {
      width: max-content;

      & div:nth-of-type(2) {
        width: 200px;
      }
    }
  }

`

// Define a type for the class keys
export type ClassKey = 'hvpsContainer' | 'cosmoContainer'
